import strFunc from "@/lib/lyg/string";

function MultyVerticalMatter(props: any) {
  let myProps = {
    matter_data: {},
    ...props
  };
  let matter_data_default = {
    idx: 0,
    part_arr: [0, "*", 0, "=", 0],
    up_num_arr: [],
    type_id: "",
    answer_idx_arr: [4],
    is_show_answer: false,
    is_dotted: false,
    course_type: "one",//one, multy
    min_max_x_size: 1,
  };
  let matter_data = {
    ...matter_data_default,
    ...myProps.matter_data
  };

  const get_init_etc_data = () => {
    let max_x_size = parseInt(strFunc.uncomma(matter_data.min_max_x_size));
    for (let i = 0; i < matter_data.part_arr.length; i++) {
      let tmp_part_str = matter_data.part_arr[i] + "";
      let tmp_part_str_len= tmp_part_str.length;
      if(i==0||i==2){
        tmp_part_str_len++;
      }
      if (tmp_part_str_len > max_x_size) {
        max_x_size = tmp_part_str_len;
      }
    }
    let is_exist_up_num = false;
    for (let i = 0; i < matter_data.up_num_arr.length; i++) {
      let tmp_part_str = matter_data.up_num_arr[i] + "";
      let tmp_up_num_str_arr = tmp_part_str.split(",");
      if (tmp_up_num_str_arr.length > max_x_size) {
        max_x_size = tmp_up_num_str_arr.length;
      }
      for (let j = 0; j < tmp_up_num_str_arr.length; j++) {
        if (!strFunc.is_empty(tmp_up_num_str_arr[j])) {
          is_exist_up_num = true;
        }
      }
    }
    return {
      max_x_size: max_x_size,
      is_exist_up_num: is_exist_up_num
    };
  };
  const get_multy_course_cal_arr = () => {
    let multy_course_arr = [];
    let a_str = matter_data.part_arr[0] + "";
    let b_str = matter_data.part_arr[2] + "";
    if (matter_data.course_type == "one") {
      let a_num_arr = a_str.split("");
      a_num_arr = a_num_arr.reverse();
      let b_num = strFunc.uncomma(parseInt(b_str));
      for (let i = 0; i < a_num_arr.length; i++) {
        let a_split_num = strFunc.uncomma(parseInt(a_num_arr[i]));
        if (i != 0) {
          a_split_num = a_split_num * Math.pow(10, i);
        }
        multy_course_arr.push(a_split_num * b_num);
      }
    } else if (matter_data.course_type == "multy") {
      let a_num = strFunc.uncomma(parseInt(a_str));
      let b_num_arr = b_str.split("");
      b_num_arr = b_num_arr.reverse();
      for (let i = 0; i < b_num_arr.length; i++) {
        let b_split_num = strFunc.uncomma(parseInt(b_num_arr[i]));
        if (i != 0) {
          b_split_num = b_split_num * Math.pow(10, i);
        }
        multy_course_arr.push(a_num * b_split_num);
      }
    }
    return multy_course_arr;
  };
  let init_etc_data = get_init_etc_data();
  let max_x_size = init_etc_data.max_x_size;
  let is_exist_up_num = init_etc_data.is_exist_up_num;
  let multy_course_arr = get_multy_course_cal_arr();

  const display_cal_sort_str = (inData: any) => {
    let opt_obj = {
      part_idx: 0,
      part_arr: matter_data.part_arr,
      ...inData
    };
    let part_idx = opt_obj.part_idx;
    let part_arr = opt_obj.part_arr;
    let num_str = part_arr[part_idx];

    if (num_str == "*") {
      num_str = "×";
    } else if (num_str == "/") {
      num_str = "÷";
    }

    return num_str;
  };

  const display_num_tds_of_part_idx = (inData: any) => {
    let opt_obj = {
      part_idx: 0,
      part_arr: matter_data.part_arr,
      is_up_num: false,
      ...inData
    };
    let part_idx = opt_obj.part_idx;
    let part_arr = opt_obj.part_arr;
    let num_str = part_arr[part_idx];
    num_str = num_str + "";
    num_str = strFunc.str_pad({ "str": num_str, "pad_length": max_x_size, "pad_str": " " });
    let num_str_arr = num_str.split("");
    //올림이라면 ',' 로 분리
    if (opt_obj.is_up_num) {
      num_str = part_arr[part_idx];
      num_str_arr = num_str.split(",");

      //0은 없애기
      for (let i = 0; i < num_str_arr.length; i++) {
        if (strFunc.is_empty(num_str_arr[i])) {
          num_str_arr[i] = "";
        }
        //더하기 일 때만 올림 보여준다.
        if (matter_data.part_arr[1] == "-") {
          num_str_arr[i] = num_str_arr[i];
        }
      }
    }
    let td_num_arr = [];
    for (let i = 0; i < max_x_size; i++) {
      td_num_arr.push(num_str_arr[i]);
    }

    let span_class_name = "matter_span";
    //올림수여부
    if (opt_obj.is_up_num) {
      span_class_name += " matter_up_span";
    }
    //정답여부
    if (opt_obj.is_up_num) {
      span_class_name += " answer_span";
      if (!matter_data.is_show_answer) {
        for (let i = 0; i < max_x_size; i++) {
          td_num_arr[i] = "";
        }
      }
    } else {
      if (strFunc.str_in_array(part_idx, matter_data.answer_idx_arr) != -1) {
        span_class_name += " answer_span";
        if (!matter_data.is_show_answer) {
          for (let i = 0; i < max_x_size; i++) {
            td_num_arr[i] = "";
          }
        }
      }
    }

    let td_tags = td_num_arr.map((td_num: string, idx: number) => {
      if(part_idx==2){
        if(idx==0){
          td_num=display_cal_sort_str({ part_idx: 1 });
        }
      }
      return (
        <td key={idx}>
          <span className={span_class_name}>{td_num}</span>
        </td>
      );
    });
    return td_tags;
  };
  const get_multy_course_tags = () => {
    let multy_course_tags = multy_course_arr.map((item: any, idx: number) => {
      let td_num_arr = item + "";
      td_num_arr = strFunc.str_pad({ "str": td_num_arr, "pad_length": max_x_size, "pad_str": " " });
      let td_num_arr_split = td_num_arr.split("");
      let td_tags = td_num_arr_split.map((td_num: string, idx: number) => {
        let span_class_name = "matter_span answer_span";
        return (
          <td key={idx}>
            {matter_data.is_show_answer?
            <span className={span_class_name}>{td_num}</span>
            :
            <span className="matter_span"></span>
            }
          </td>
        );
      });
      let tr_class_name = "";
      if (idx == 0) {
        tr_class_name = "tr_top_border";
      }
      return (
        <tr key={idx} className={tr_class_name}>
          {td_tags}
        </tr>
      );
    });
    return multy_course_tags;
  };

  let height_table_class_name = "matter_height_table";
  if (matter_data.is_dotted) {
    height_table_class_name += " matter_dotted_table";
  }

  return (
    <div className="matter_wrap">
      <span className="matter_idx_num">
        {matter_data.idx + 1}
      </span>
      <table className={height_table_class_name}>
        <tbody>
          {(matter_data.is_show_answer && is_exist_up_num) &&
            <tr>
              {display_num_tds_of_part_idx({
                part_arr: matter_data.up_num_arr,
                part_idx: 0,
                is_up_num: true
              })}
            </tr>
          }
          <tr>
            {display_num_tds_of_part_idx({ part_idx: 0 })}
          </tr>
          <tr>
            {display_num_tds_of_part_idx({ part_idx: 2 })}
          </tr>
          {get_multy_course_tags()}
          <tr className={matter_data.is_show_answer?"tr_top_border":""}>
            {display_num_tds_of_part_idx({ part_idx: 4 })}
          </tr>
        </tbody>
      </table>
    </div>
  );
}
export default MultyVerticalMatter;