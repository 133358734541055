import strFunc from "@/lib/lyg/string";

function DivVerticalMatter(props: any) {
  let myProps = {
    matter_data: {},
    ...props
  };
  let matter_data_default = {
    idx: 0,
    part_arr: [0, "/", 0, "=", 0, 0],
    type_id: "",
    answer_idx_arr: [4],
    is_show_answer: false,
    is_dotted: false,
    is_min_height:false,
  };
  let matter_data = {
    ...matter_data_default,
    ...myProps.matter_data
  };
  const get_init_etc_data = () => {
    let max_x_size = 1;
    let tmp_a_part_str = matter_data.part_arr[0] + "";
    let tmp_b_part_str = matter_data.part_arr[2] + "";
    if (tmp_a_part_str.length > max_x_size) {
      max_x_size = tmp_a_part_str.length;
    }
    let left_x_size = tmp_b_part_str.length;
    let min_height_size=(tmp_a_part_str.length-(tmp_b_part_str.length-1))*2;
    return {
      max_x_size: max_x_size,
      left_x_size: left_x_size,
      min_height_size:min_height_size
    };
  };
  const get_coures_cal_arr = () => {
    let course_dafault = {
      num: 0,
      depth: 0,
      top_border: false,
    };
    let course_arr: any = [];
    if (matter_data.part_arr[0] == 0 || matter_data.part_arr[2] == 0) {
      return course_arr;
    }
    let a_str = matter_data.part_arr[0] + "";
    let b_str = matter_data.part_arr[2] + "";
    let b_num = parseInt(b_str);
    let left_num_str = matter_data.part_arr[5] + "";

    let a_num_arr = a_str.split("");
    let last_up_num = 0;
    let last_is_zero_result=false;
    for (let a_i = 0; a_i < a_num_arr.length; a_i++) {
      let a_split_num = parseInt(a_num_arr[a_i]);
      a_split_num = a_split_num + (last_up_num * 10);
      let tmp_anser = 0;
      if (a_split_num != 0 && b_num != 0) {
        tmp_anser = Math.floor(a_split_num / b_num);
      }
      last_up_num = a_split_num % b_num;
      let tmp_row_result: any = b_num * tmp_anser;
      if (a_num_arr.length - 1 == a_i) {
        if (tmp_row_result == 0) {
          tmp_row_result = "";
        }
      }
      if (a_i != 0) {
        if(tmp_row_result==0){
          
        }else{
          let tmp_is_add_course=true;
          if(last_is_zero_result){
            if(course_arr.length==0){
              tmp_is_add_course=false;
            }
          }
          if(tmp_is_add_course){
            course_arr.push({
              ...course_dafault,
              num: a_split_num,
              depth: a_i,
              top_border: true,
            });
          }
        }
      }
      if(tmp_row_result!=0){
        course_arr.push({
          ...course_dafault,
          num: tmp_row_result,
          depth: a_i,
          top_border: false,
        });
      }
      if(tmp_row_result==0){
        last_is_zero_result=true;
      }else{
        last_is_zero_result=false;
      }
    }
    if (course_arr[course_arr.length - 1].num == 0) {
      course_arr.push({
        ...course_dafault,
        num: "",
        depth: a_num_arr.length - 1,
        top_border: false,
      });
    } else {
      course_arr.push({
        ...course_dafault,
        num: matter_data.part_arr[5],
        depth: a_num_arr.length - 1,
        top_border: true,
      });
    }

    return course_arr;
  };
  let init_etc_data = get_init_etc_data();
  let max_x_size = init_etc_data.max_x_size;
  let left_x_size = init_etc_data.left_x_size;
  let min_height_size=init_etc_data.min_height_size;
  let left_x_arr:any = [];
  for (let i = 0; i < left_x_size; i++) {
    left_x_arr.push(i);
  }
  let course_arr = get_coures_cal_arr();

  const display_num_tds_of_part_idx = (inData: any) => {
    let opt_obj = {
      part_idx: 0,
      part_arr: matter_data.part_arr,
      ...inData
    };

    let part_idx = opt_obj.part_idx;
    let part_arr = opt_obj.part_arr;
    let num_str = part_arr[part_idx];
    let row_max_x_size = max_x_size;
    if (part_idx == 2) {
      row_max_x_size = left_x_size;
    }
    num_str = num_str + "";
    num_str = strFunc.str_pad({ "str": num_str, "pad_length": row_max_x_size, "pad_str": " " });
    let num_str_arr = num_str.split("");
    let td_num_arr = [];
    for (let i = 0; i < row_max_x_size; i++) {
      td_num_arr.push(num_str_arr[i]);
    }

    let span_class_name = "matter_span";
    //정답여부
    if (strFunc.str_in_array(part_idx, matter_data.answer_idx_arr) != -1) {
      span_class_name += " answer_span";
      if (!matter_data.is_show_answer) {
        for (let i = 0; i < row_max_x_size; i++) {
          td_num_arr[i] = "";
        }
      }
    }

    let td_tags = td_num_arr.map((td_num: string, idx: number) => {
      let tmp_td_class_name = "";
      let tmp_td_style: any = {};
      let tmp_border_div: any = "";
      if (part_idx == 0) {
        tmp_td_class_name = "td_top_border";
        if (idx == 0) {
          tmp_td_style["position"] = "relative";
          tmp_td_style["borderLeft"] = "0px";
          tmp_border_div = (
            <div className="divide_left_border">
              )
            </div>
          );
        }
      }
      if (part_idx == 2) {
        if (idx == td_num_arr.length - 1) {
          tmp_td_style["borderRight"] = "0px";
        }
      }
      return (
        <td key={idx} className={tmp_td_class_name} style={tmp_td_style}>
          {tmp_border_div}
          <span className={span_class_name}>{td_num}</span>
        </td>
      );
    });
    return td_tags;
  };

  const get_empty_td_tags = (inData: any) => {
    let opt_obj = {
      is_top_border: false,
      size: 0,
      ...inData
    };
    let is_top_border = opt_obj.is_top_border;
    let size = opt_obj.size;
    let empty_td_arr = [];
    for (let i = 0; i < size; i++) {
      empty_td_arr.push(i);
    }
    let td_tags = empty_td_arr.map((item: any, idx: number) => {
      let td_class_name = "";
      if (is_top_border && matter_data.is_show_answer) {
        td_class_name = "td_top_border";
      }
      return (
        <td key={idx} className={td_class_name}>
          <span className="matter_span"></span>
        </td>
      );
    });
    return td_tags;
  };

  const get_course_tags = () => {
    let course_tags = course_arr.map((item: any, idx: number) => {
      let td_num_str = item.num + "";
      let td_num_arr = td_num_str.split("");
      let td_tags = td_num_arr.map((td_num: string, idx: number) => {
        let td_class_name = "";
        if (item.top_border && matter_data.is_show_answer) {
          td_class_name = "td_top_border";
        }
        let span_class_name = "matter_span answer_span";
        return (
          <td key={idx} className={td_class_name}>
            {matter_data.is_show_answer ?
              <span className={span_class_name}>{td_num}</span>
              :
              <span className="matter_span"></span>
            }
          </td>
        );
      });
      let left_td_len = item.depth - (td_num_arr.length - 1);
      let right_td_len = max_x_size - (item.depth + 1);
      let left_td_tags = get_empty_td_tags({
        size: left_td_len,
        is_top_border: item.top_border
      });
      let right_td_tags = get_empty_td_tags({
        size: right_td_len,
        is_top_border: item.top_border
      });
      return (
        <tr key={idx}>
          {left_x_arr.map((empty_td: any, empty_td_idx: number) => {
            return (<td key={empty_td_idx}></td>);
          })}
          {left_td_tags}
          {td_tags}
          {right_td_tags}
        </tr>
      );
    });
    return course_tags;
  };
  const get_empty_height_tr_tags=()=>{
    //min_height_size
    if(matter_data.is_min_height==false){
      return "";
    }
    let empty_height_tr_size=min_height_size-course_arr.length;
    let empty_height_tr_tags=[];
    for(let i=0;i<empty_height_tr_size;i++){
      empty_height_tr_tags.push(i);
    }
    let tr_tags=empty_height_tr_tags.map((item:any,idx:number)=>{
      return (
        <tr key={idx}>
          {left_x_arr.map((empty_td: any, empty_td_idx: number) => {
            return (<td key={empty_td_idx}></td>);
          })}
          {get_empty_td_tags({size:max_x_size})}
        </tr>
      );
    });
    return tr_tags;
  };

  let height_table_class_name = "matter_height_table";
  if (matter_data.is_dotted) {
    height_table_class_name += " matter_dotted_table";
  }

  return (
    <div className="matter_wrap">
      <span className="matter_idx_num">
        {matter_data.idx + 1}
      </span>
      <table className={height_table_class_name}>
        <tbody>
          <tr>
            {left_x_arr.map((item: any, idx: number) => {
              return (<td key={idx}></td>);
            })}
            {display_num_tds_of_part_idx({ part_idx: 4 })}
          </tr>
          <tr>
            {display_num_tds_of_part_idx({ part_idx: 2 })}
            {display_num_tds_of_part_idx({ part_idx: 0 })}
          </tr>
          {get_course_tags()}
          {get_empty_height_tr_tags()}
        </tbody>
      </table>
    </div>
  );
};
export default DivVerticalMatter;