import strFunc from "@/lib/lyg/string";
import MatterTagsArea from "./matter_tags";

function MatterPageTableArea(props: any) {
  let myProps = {
    menu_info: {},
    matter_data_arr: [],
    is_show_first_answer: true,
    is_show_answer: false,
    ...props
  };
  let menu_info = myProps.menu_info;
  let matter_data_arr = myProps.matter_data_arr;
  let is_show_first_answer = myProps.is_show_first_answer;
  let is_show_answer = myProps.is_show_answer;

  let split_num = 1;
  let split_each_num = 0;
  let split_div_per = 100;
  let page_box_arr: any = [];
  
  if (!strFunc.is_empty(menu_info)) {
    split_num = menu_info.a_layout_split;
    split_each_num = 0;
    split_div_per = 100;
    if (split_num > 0) {
      split_each_num = Math.ceil(matter_data_arr.length / split_num);
      split_div_per = Math.floor(100 / split_num);
    }

    for (let i = 0; i < split_num; i++) {
      let page_box_data: any = {
        matter_data_arr: []
      };
      let start_idx = i * split_each_num;
      let max_idx = (i + 1) * split_each_num;
      for (let j = 0; j < matter_data_arr.length; j++) {
        if (j >= start_idx && j < max_idx) {
          page_box_data.matter_data_arr.push(matter_data_arr[j]);
        }
      }
      page_box_arr.push(page_box_data);
    }
  }

  const table_colgroup_tags = () => {
    let colgroup_tags: any = page_box_arr.map((item: any, idx: number) => {
      return (
        <col key={idx} style={{ width: split_div_per + "%" }}></col>
      );
    });
    return colgroup_tags;
  };

  const get_tr_td_tags = (inData: any) => {
    let opt_obj = {
      row_i: 0,
      ...inData
    };
    let row_i = opt_obj.row_i;
    let tr_td_data_arr: any = [];
    for (let i = 0; i < page_box_arr.length; i++) {
      let page_box_data = page_box_arr[i];
      let row_matter_data_arr = page_box_data["matter_data_arr"];
      if (row_matter_data_arr.length - 1 >= row_i) {
        tr_td_data_arr.push(row_matter_data_arr[row_i]);
      }
    }

    let tr_td_tags: any = tr_td_data_arr.map((matter: any, matter_idx: number) => {
      return (
        <td key={matter_idx}>
          <MatterTagsArea
            row_matter_data_arr={[matter]}
            is_show_first_answer={is_show_first_answer}
            is_show_answer={is_show_answer}
          ></MatterTagsArea>
        </td>
      );
    });
    return tr_td_tags;
  };

  const get_matter_page_tr_tags = () => {
    let tr_row_arr = [];
    for (let i = 0; i < split_each_num; i++) {
      tr_row_arr.push(i);
    };
    let page_tr_tags = tr_row_arr.map((tr_i: any, tr_idx: number) => {
      let tr_td_tags = get_tr_td_tags({ row_i: tr_i });
      return (
        <tr key={tr_idx}>
          {tr_td_tags}
        </tr>
      );
    });
    return page_tr_tags;
  };

  return (
    <div>
      <table style={{ width: "100%",minHeight:"900px" }}>
        <colgroup>
          {table_colgroup_tags()}
        </colgroup>
        <tbody>
          {get_matter_page_tr_tags()}
        </tbody>
      </table>
    </div>
  );
};
export default MatterPageTableArea;