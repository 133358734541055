import { useState, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import MathSearchDataFunc from "@/pcomponents/common/content/math/search_data/math_search_data";
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";

function SearchArea(props: any) {
  let myProps = {
    listOpt: {},
    setListOpt: (inData: any) => { },
    get_page_one_by_ajax: (inData: any) => { },
    ...props
  };
  let listOpt = myProps.listOpt;
  const [section_info_arr, set_section_info_arr] = useState([]);
  const [menu_info_arr, set_menu_info_arr] = useState([]);

  useEffect(() => {
    get_section_list_by_ajax({});
  }, []);

  const on_change_list_opt = (inData: any) => {
    let opt_obj = {
      key: "",
      value: "",
      add_change_data: {},
      ...inData
    };

    let new_list_opt = {
      ...listOpt,
    };
    new_list_opt[opt_obj.key] = opt_obj.value;
    new_list_opt = {
      ...new_list_opt,
      ...opt_obj.add_change_data
    };
    myProps.setListOpt(new_list_opt);

    if (opt_obj.key == "s_stu_grade") {
      get_section_list_by_ajax({
        s_stu_grade: opt_obj.value,
      });
    } else if (opt_obj.key == "s_stu_season") {
      get_section_list_by_ajax({
        s_stu_season: opt_obj.value,
      });
    } else if (opt_obj.key == "s_section_seq") {
      get_menu_list_by_ajax({
        s_section_seq: opt_obj.value,
      });
    } else if (opt_obj.key == "s_menu_seq") {
      myProps.get_page_one_by_ajax({
        s_stu_grade: listOpt.s_stu_grade,
        s_stu_season: listOpt.s_stu_season,
        s_section_seq: listOpt.s_section_seq,
        s_menu_seq: opt_obj.value
      });
    }
    myProps.setListOpt(new_list_opt);
  };

  const get_section_list_by_ajax = (inOptObj: any) => {
    let form_json_data = {
      s_stu_grade: listOpt.s_stu_grade,
      s_stu_season: listOpt.s_stu_season,
      order_id: "a_order_num",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
      ...inOptObj
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_section/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_section_info_arr(response.data["data"]["info_arr"]);
          if (response.data["data"]["info_arr"].length > 0) {
            get_menu_list_by_ajax({
              s_stu_grade: form_json_data.s_stu_grade,
              s_stu_season: form_json_data.s_stu_season,
              s_section_seq: response.data["data"]["info_arr"][0]["a_seq"]
            });
          }
        } else {

        }
      });
  };
  const get_menu_list_by_ajax = (inOptObj: any) => {
    let form_json_data = {
      s_stu_grade: listOpt.s_stu_grade,
      s_stu_season: listOpt.s_stu_season,
      s_section_seq: listOpt.s_section_seq,
      order_id: "a_order_num",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
      ...inOptObj
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_menu/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_menu_info_arr(response.data["data"]["info_arr"]);
          let add_change_data: any = {
            s_stu_grade: form_json_data.s_stu_grade,
            s_stu_season: form_json_data.s_stu_season,
            s_section_seq: form_json_data.s_section_seq
          };
          if (response.data["data"]["info_arr"].length > 0) {
            on_change_list_opt({
              key: "s_menu_seq", value: response.data["data"]["info_arr"][0]["a_seq"],
              add_change_data: add_change_data
            });
          } else {
            on_change_list_opt({
              key: "s_menu_seq", value: "",
              add_change_data: add_change_data
            });
          }
        } else {

        }
      });
  };

  let section_select_option_arr:any = [];
  for (let i = 0; i < section_info_arr.length; i++) {
    let item = section_info_arr[i];
    section_select_option_arr.push({ value: item["a_seq"], text: item["a_title"] });
  }
  let menu_select_option_arr: any = [];
  for (let i = 0; i < menu_info_arr.length; i++) {
    let item = menu_info_arr[i];
    menu_select_option_arr.push({ value: item["a_seq"], text: item["a_title"] });
  }

  let now_section_idx = 0;
  for (let i = 0; i < section_select_option_arr.length; i++) {
    if (section_select_option_arr[i]["value"] == listOpt.s_section_seq) {
      now_section_idx = i;
      break;
    }
  }

  let now_menu_idx = 0;
  for (let i = 0; i < menu_select_option_arr.length; i++) {
    if (menu_select_option_arr[i]["value"] == listOpt.s_menu_seq) {
      now_menu_idx = i;
      break;
    }
  }

  const go_move_section_plus_minus = (inData: any) => {
    let opt_obj = {
      sort: "+",//+,-
      ...inData
    };

    if (opt_obj.sort == "+") {
      now_section_idx += 1;
    } else {
      now_section_idx -= 1;
    }
    if (now_section_idx < 0) {
      now_section_idx = section_select_option_arr.length - 1;
    }
    if (now_section_idx >= section_select_option_arr.length) {
      now_section_idx = 0;
    }
    if(section_select_option_arr[now_section_idx]){
      on_change_list_opt({ key: "s_section_seq", value: section_select_option_arr[now_section_idx]["value"] });
    }
  };

  const go_move_menu_plus_minus = (inData: any) => {
    let opt_obj = {
      sort: "+",//+,-
      ...inData
    };

    if (opt_obj.sort == "+") {
      now_menu_idx += 1;
    } else {
      now_menu_idx -= 1;
    }
    if (now_menu_idx < 0) {
      now_menu_idx = menu_select_option_arr.length - 1;
    }
    if (now_menu_idx >= menu_select_option_arr.length) {
      now_menu_idx = 0;
    }
    if(menu_select_option_arr[now_menu_idx]){
      on_change_list_opt({ key: "s_menu_seq", value: menu_select_option_arr[now_menu_idx]["value"] });
    }
  };

  return (
    <div className="search_box">
      <div>
        학년:
        <span className="ml-2" style={{ display: "inline-block" }}>
          <SelectBoxBar
            valueTextArr={[
              { value: "", text: "전체" },
              ...MathSearchDataFunc.select_stu_grade_option_arr
            ]}
            value={listOpt.s_stu_grade}
            on_change={(inData: any) => {
              on_change_list_opt({ key: "s_stu_grade", value: inData.value });
            }}
          ></SelectBoxBar>
        </span>
      </div>
      <div className="mt-1">
        학기:
        <span className="ml-2" style={{ display: "inline-block" }}>
          <SelectBoxBar
            valueTextArr={[
              { value: "", text: "전체" },
              ...MathSearchDataFunc.select_stu_season_option_arr
            ]}
            value={listOpt.s_stu_season}
            on_change={(inData: any) => {
              on_change_list_opt({ key: "s_stu_season", value: inData.value });
            }}
          ></SelectBoxBar>
        </span>
        <span className="ml-1">섹션:</span>
        <select className="search_input ml-1" name="s_section_seq" value={listOpt.s_section_seq}
          onChange={(e: any) => {
            on_change_list_opt({ key: e.target.name, value: e.target.value });
          }}>
          <option value="">섹션</option>
          {section_select_option_arr.map((item: any, idx: number) => {
            return (
              <option key={idx} value={item["value"]}>{idx + 1}. {item["text"]}</option>
            );
          })}
        </select>
        <span className="ml-1">
          {now_section_idx+1}/{section_select_option_arr.length}
        </span>
        <button className="btn btn-dark ml-1" onClick={() => { go_move_section_plus_minus({ sort: "-" }); }}>◀</button>
        <button className="btn btn-dark ml-1" onClick={() => { go_move_section_plus_minus({ sort: "+" }); }}>▶</button>
        <span className="ml-1">메뉴:</span>
        <select className="search_input ml-1" name="s_menu_seq" value={listOpt.s_menu_seq}
          onChange={(e: any) => {
            on_change_list_opt({ key: e.target.name, value: e.target.value });
          }}>
          <option value="">메뉴</option>
          {menu_select_option_arr.map((item: any, idx: number) => {
            return (
              <option key={idx} value={item["value"]}>{idx + 1}) {item["text"]}</option>
            );
          })}
        </select>
        <span className="ml-1">
          {now_menu_idx+1}/{menu_select_option_arr.length}
        </span>
        <button className="btn btn-dark ml-1" onClick={() => { go_move_menu_plus_minus({ sort: "-" }); }}>◀</button>
        <button className="btn btn-dark ml-1" onClick={() => { go_move_menu_plus_minus({ sort: "+" }); }}>▶</button>
      </div>
    </div>
  );
};
export default SearchArea;