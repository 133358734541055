import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import SearchArea from "./area/search";
import strFunc from '@/lib/lyg/string';
import ShowMatterPage from "@/pcomponents/common/content/math/show_page";
import { useReactToPrint } from 'react-to-print';

function ShowPageArea(props: any) {
  let myProps = {
    get_page_cnt: 1,
    ...props
  };
  const ShowMatterPageRef = useRef<any>(null);
  const [listOpt, setListOpt] = useState({
    s_stu_grade: "8",
    s_stu_season: "1",
    s_section_seq: "",
    s_menu_seq: "",
    get_page_cnt: myProps.get_page_cnt,
  });
  const default_page_info = {
    a_seq: "",
    a_menu_seq: "",
    a_title: "",
    section_info: null,
    menu_info: null,
    matter_arr: [],
    matter_data_arr: [],
  };
  const [page_info, set_page_info] = useState({ ...default_page_info });
  const [page_info_arr, set_page_info_arr] = useState([]);
  let [is_show_first_answer, set_is_show_first_answer] = useState(false);
  let [is_show_answer, set_is_show_answer] = useState(false);

  const printRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {

  }, []);

  const get_page_one_by_ajax = (inData: any) => {
    let opt_obj = {
      s_menu_seq: listOpt.s_menu_seq,
      get_page_cnt: listOpt.get_page_cnt,
      ...inData
    };
    let form_json_data = {
      s_menu_seq: opt_obj.s_menu_seq,
      except_page_seq_arr: [page_info.a_seq],
      get_page_cnt: opt_obj.get_page_cnt,
    };

    if (strFunc.is_empty(form_json_data.s_menu_seq)) {
      set_page_info({
        ...default_page_info
      });
      return false;
    }

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_page/get_page_one', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_page_info({
            ...default_page_info,
            ...response.data["data"]["page_info"]
          });
          
          set_page_info_arr(response.data["data"]["page_info_arr"]);
        } else {
          set_page_info({
            ...default_page_info
          });
        }
      });
  };

  const go_print = () => {
    print_page();
  };

  const print_page=()=>{
    handlePrint();
  };

  const get_matter_page_tags = () => {
    let matter_page_tags = page_info_arr.map((item: any, idx: number) => {
      return (
        <div key={idx} >
          <ShowMatterPage
            page_info={item}
            is_show_first_answer={is_show_first_answer}
            is_show_answer={is_show_answer}
          ></ShowMatterPage>
        </div>
      );
    });
    return matter_page_tags;
  };

  let select_page_cnt_num_arr=[];
  for(let i=1;i<=30;i++){
    select_page_cnt_num_arr.push(i);
  }

  return (
    <div>
      <div>
        <SearchArea
          listOpt={listOpt}
          setListOpt={setListOpt}
          get_page_one_by_ajax={get_page_one_by_ajax}
        ></SearchArea>
      </div>
      <div style={{ textAlign: "center", marginTop: 10, marginBottom: 10 }}>
        <select className="border" value={listOpt.get_page_cnt}
          onChange={(e: any) => {
            setListOpt({
              ...listOpt,
              get_page_cnt: e.target.value
            });
            get_page_one_by_ajax({ get_page_cnt: e.target.value });
          }}>
          {
            select_page_cnt_num_arr.map((item: any) => {
              return <option key={item} value={item}>{item}</option>;
            })
          }
        </select>
        <button className={"btn-m " + (is_show_answer ? "btn-sky" : "btn-dark")}
          onClick={() => { set_is_show_answer(!is_show_answer); }} style={{ marginLeft: 10 }}>정답보기</button>
        <button className={"btn-m " + (is_show_first_answer ? "btn-sky" : "btn-dark")}
          onClick={() => { set_is_show_first_answer(!is_show_first_answer); }} style={{ marginLeft: 10 }}>처음정답보기</button>
        <button className={"btn-m btn-dark"} onClick={go_print} style={{ marginLeft: 10 }}>프린트</button>
        <button className={"btn-m btn-dark"}
          onClick={() => { get_page_one_by_ajax({}); }} style={{ marginLeft: 10 }}>다른문제</button>
      </div>
      <div style={{marginTop:20}}>
        <div ref={printRef} style={{ width: "21cm", margin: "0 auto" }}>
          {get_matter_page_tags()}
        </div>
      </div>
    </div>
  );
};
export default ShowPageArea;