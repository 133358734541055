import React from 'react';
import BoxOneTwoMatter from "./tags/low/box_one_two";
import BoxTwoOneMatter from "./tags/low/box_two_one";
import HorizontalMatter from "./tags/low/horizontal";
import VerticalMatter from "./tags/low/vertical";
import DivisionLeftMatter from "./tags/division/division_left";
import FractionHorizontalMatter from "./tags/fraction/horizontal";
import MultyVerticalMatter from "./tags/multy/vertical";
import DivVerticalMatter from "./tags/division/vertical";
import DivisionCheckMatter from "./tags/division/division_check";

function MatterTagsArea(props: any) {
  let myProps = {
    row_matter_data_arr: [],
    matter_idx: 0,
    is_show_first_answer: true,
    is_show_answer: false,
    ...props
  };

  const get_matter_tags = () => {
    let matter_tags = myProps.row_matter_data_arr.map((item: any, idx: number) => {
      let row_matter_data = {
        ...item
      };

      row_matter_data.is_show_answer = myProps.is_show_answer;
      if (myProps.is_show_answer == false) {
        if (item.idx == 0) {
          row_matter_data.is_show_answer = myProps.is_show_first_answer;
        }
      }

      return (
        <React.Fragment
          key={idx}>
          {row_matter_data.layout_id == "box_one_two" &&
            <BoxOneTwoMatter
              matter_data={row_matter_data}
            ></BoxOneTwoMatter>
          }
          {row_matter_data.layout_id == "box_two_one" &&
            <BoxTwoOneMatter
              matter_data={row_matter_data}
            ></BoxTwoOneMatter>
          }
          {row_matter_data.layout_id == "horizontal" &&
            <HorizontalMatter
              matter_data={row_matter_data}
            ></HorizontalMatter>
          }
          {row_matter_data.layout_id == "vertical" &&
            <VerticalMatter
              matter_data={row_matter_data}
            ></VerticalMatter>
          }
          {row_matter_data.layout_id == "division_left" &&
            <DivisionLeftMatter
              matter_data={row_matter_data}
            ></DivisionLeftMatter>
          }
          {row_matter_data.layout_id == "fraction" &&
            <FractionHorizontalMatter
              matter_data={row_matter_data}
            ></FractionHorizontalMatter>
          }
          {row_matter_data.layout_id == "multy_height" &&
            <MultyVerticalMatter
              matter_data={row_matter_data}
            ></MultyVerticalMatter>
          }
          {row_matter_data.layout_id == "division_height" &&
            <DivVerticalMatter
              matter_data={row_matter_data}
            ></DivVerticalMatter>
          }
          {row_matter_data.layout_id == "division_check" &&
            <DivisionCheckMatter
              matter_data={row_matter_data}
            ></DivisionCheckMatter>
          }
        </React.Fragment>
      );
    });
    return matter_tags;
  };

  return (
    <>
      {get_matter_tags()}
    </>
  );
}
export default MatterTagsArea;